import { FC, useState } from "react";
import { ToastContainer } from "react-toastify";

import { RouteHandler } from "components/route-handler";

import "react-toastify/dist/ReactToastify.min.css";

import 'rsuite/dist/rsuite.min.css';

import "./assets/css/app-modern-dark.min.css";
import "./assets/css/override-rsuite.css";
import "./assets/css/override-tostify.css";

import "./assets/css/my-dark.css";

import { UserProvider } from "contexts/user";

import { Footer } from "components/ui/footer";
import { Header } from "components/ui/header";

import { CustomProvider } from "rsuite";
import { ModalProvider } from './contexts/modal';


const App: FC = () => {
  const [expanded, setExpanded] = useState(true);
  return (
    <CustomProvider theme="dark" rtl>
      <UserProvider>
        <ModalProvider>
          <Header />
          <div className="container-fluid">
            <div className="wrapper">
              {/* <Sidebar expanded={expanded} setExpanded={setExpanded}/> */}
              <div className="content-page d-flex flex-column" style={{justifyContent:"space-between"}}>
                <div className="content d-block" >
                  <RouteHandler />
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </ModalProvider>
      </UserProvider>
      <ToastContainer
        theme="colored"
        position="bottom-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        
    </CustomProvider >
  );
};


export default App;
