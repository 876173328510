import axios from "axios";
import qs from "qs";

import { APP_BASE_URL } from "./config";



const headers = {};

const paramsSerializer = (params: any) => {
  return qs.stringify(params, { indices: false, skipNulls: true });
};

const appNetworkManager = axios.create({
  baseURL: APP_BASE_URL,
  headers,
  paramsSerializer,
});

appNetworkManager.interceptors.request.use((req) => {
  if (process.env.NODE_ENV !== "production") {
    console.log("API Req", req);
  }
  return req;
});

appNetworkManager.interceptors.response.use(
  (res) => {
    if (process.env.NODE_ENV !== "production") {
      console.log("API Res", res);
    }
    return res;
  },
  (error) => {
    if (process.env.NODE_ENV !== "production") {
      console.log("API Error", error);
    }
    if (error.response) {
      console.log(error.response.data);
    }

    return Promise.reject(error);
  }
);

export { appNetworkManager };
