import { useCallback, useState, useEffect, useRef } from 'react';

import { showToast } from 'util/toast';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, ButtonToolbar, Button, Schema, CheckPicker } from 'rsuite';
import { addBroker } from 'core/api';

import TextField from "components/form/textfield";
import Field from 'components/form/field';
import { useUser } from 'contexts/user';

const { StringType, NumberType, ArrayType } = Schema.Types;
const model = Schema.Model({
  First_Name: StringType().isRequired("نام را وارد کنید."),
  Last_Name: StringType().isRequired("نام خانوادگی را وارد کنید."),
  Phone_Number: NumberType("لطفا عدد انگلیسی وارد کنید.").pattern(/^([0|\\+[0-9]{1,5})?([7-9][0-9]{9})$/ , "لطفا شماره تلفن همراه را به صورت صحیح وارد کنید.").isRequired("شماره همراه را وارد کنید."),
  Category: StringType().isRequired("دسته اصلی را وارد کنید."),
  Sub_Category: ArrayType().isRequired("دسته فرعی را انتخاب کنید."),
});



const categories: any = {
  "مواد اولیه": [
    "مواد شیمیائی",
    "روان کننده ها و روغنها",
    "مواد پلیمری",
    "مواد لاستیکی",
    "رنگ دانه ها",
    "حلالها",
    "مواد اولیه صنایع غذائی",
    "مواد سلولزی",
    "قطعات الکتریکی و الکترونیکی",
    "بلبرینگ و رولبرینگ",
    "پیچ و مهره"
  ],

  "شمش، فلزات و آهن آلات": [
    "فلزات و شمش",
    "میلگرد",
    "ورق",
    "تیرآهن",
    "پروفیل",
    "لوله",
    "ناودانی",
    "نبشی",
    "سپری",
  ],

  "مصالح ساختمانی": [
    "سیمان",
    "گچ",
    "آهک و پوکه",
    "آجر و سفال",
    "شن و ماسه",
    "بلوک، تیرچه، لوله و محصولات سیمانی",
    "مصالح عایق کاری",
    "سنگ و سرامیک",
    "درب و پنجره",
    "ملزومات لوله کشی و برق کشی",
    "چینی بهداشتی",
    "شیرآلات",
    "تجهیزات سرمایش و گرمایش",
    "تجهیزات آشپزخانه",
    "سیستمهای هوشمند",
    "دکوراسیون",
  ],

  "نهاده های کشاورزی و دامی": [
    "جوجه یکروزه گوشتی",
    "جوجه یکروزه تخمگذار",
    "تخم مرغ",
    "مرغ زنده",
    "مرغ کشتار",
    "گوشت مرغ مادر",
    "جوجه یکروزه بوقلمون",
    "بوقلمون زنده",
    "دام کشتار",
    "گوشت منجمد",
    "پودرماهی",
    "ذرت",
    "گندم",
    "جو",
    "یونجه",
    "کاه",
    "کنجاله آفتابگردان",
    "کنجاله سویا",
    "کنجاله کلزا",
    "کنجاله تخم پنبه",
    "سبوس برنج",
    "سبوس گندم",
    "متیونین",
    "تردونین",
    "کولین",
    "لیزین",
    "فسفات",
    "باگاس",
    "کربنات کلسیم",
    "جوش شیرین",
    "پودر چربی"
  ],
};


const AddUser = ({parent}:any) => {

  const { setUsersTrigger, usersTrigger } = useUser();
  const navigate = useNavigate();
  const formRef = useRef<any>();
  const [isReal, setIsReal] = useState(true);
  const [formError, setFormError] = useState<any>();
  const [formValue, setFormValue] = useState<any>({});
  const [formDefaultValue, setFormDefaultValue] = useState<any>({
    Category: parent
  });
  
  const [submitLoading, setSubmitLoading] = useState(false);
  const [tlpValue, setTlpValue] = useState('0');
  const tlpValueRef = useRef<any>('0');
  tlpValueRef.current = tlpValue;


  const addBrokerWithApi = async(addBrokerData: any) => {
    try {
      const { status, data } = await addBroker(addBrokerData);
      if (status == 200 && data.Status == 1) {
        return data.Broker_Code;
      }
      else if (status == 200 && data.Status == -2) {
        showToast("کاربری با این شماره تلفن موجود است.", "error");
        return -1;
      }
    } catch (err) {
      console.log(err);
    }
    showToast(" مشکلی پیش آمده لطفا بعدا امتحان کنید.", "error");
    return -1;
  };

  const handleSubmit2 = useCallback(async() => {
    if (formRef.current && !formRef.current.check()) {
      console.log(formError,'Form Error');
      return;
    }
    console.log(formValue,'Form Value');
    
    const subCategory = formValue.Sub_Category.join(";");

    const payload = { ...formValue, Sub_Category: subCategory, Is_Real:isReal, Date: new Date() };
    console.log(payload);

    setSubmitLoading(true);
    const brokerCode = await addBrokerWithApi(payload);
    setSubmitLoading(false);
    console.log(brokerCode);
    
    if(brokerCode!=-1){
      navigate(`/success/${brokerCode}`);
    }
    //redirect if 1

  },[formValue, usersTrigger, isReal]);


  const [selectedCategory, setSelectedCategory] = useState("");


  const handleCategoryChange = (val: any) => {
    setSelectedCategory(val);
  };
  
  return (
    <div className="content">

      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <h4 className="page-title">ثبت نام کارگزار { isReal?"حقیقی":"حقوقی"} در دسته { parent}</h4>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="col-12">
            <Button type="button" className="ms-1" style={{ borderRadius:"10px 10px 0px 0px", color: isReal?"#000":"var(--dark-gray-2)", backgroundColor: isReal?"var(--a-purple)":"var(--dark-gray)", fontWeight: "bold" }} onClick={()=>{setIsReal(true)}}>شخص حقیقی هستم</Button>
            <Button type="button" className="ms-1" style={{ borderRadius:"10px 10px 0px 0px", color:!isReal?"#000":"var(--dark-gray-2)", backgroundColor:!isReal?"var(--a-purple)":"var(--dark-gray)", fontWeight: "bold" }} onClick={()=>{setIsReal(false)}}>شخص حقوقی هستم</Button>
          </div>
          <div className="card">
            <div className="card-body">
              <Form ref={formRef} formDefaultValue={formDefaultValue} onSubmit={handleSubmit2} onChange={setFormValue} onCheck={setFormError} formError={formError} model={model}>
                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <div className="mb-3">
                      <TextField name="First_Name" label={isReal?"نام":"نام شرکت"} />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="mb-3">
                      <TextField name="Last_Name" label={isReal?"نام خانوادگی": "نام نماینده"}/>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="mb-3">
                      <TextField name="Phone_Number" label="شماره تلفن همراه" />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="mb-3">
                      <TextField disabled name="Category" label="دسته اصلی" value={parent} />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                      <div className="mb-3">
                      <Field onChange={handleCategoryChange} name="Sub_Category" placeholder="انتخاب" className={`select-text-right`} dir="rtl" label="دسته فرعی" accepter={CheckPicker}
                          data={ 
                            categories[parent]?.map((category: any) => { 
                              return {
                                label: category,
                                value: category,
                              }
                            })
                          } />
                    </div>
                  </div>
                <ButtonToolbar>
                  <div className="mb-3 col-xl-4 mx-auto mt-3">
                    <Button type="submit" block style={{color:"#000", backgroundColor:"var(--a-purple)", fontWeight:"bold"}} loading={submitLoading} disabled={submitLoading} >ثبت</Button>
                  </div>
                </ButtonToolbar>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default AddUser;