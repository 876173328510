import {
  FC,
  createContext,
  useState,
  ReactChild,
  useContext,
  useEffect,
  useCallback,
} from "react";

interface UserContext {
  user?: any;
  setUser?: any
  userTrigger?: any;
  setUserTrigger?: any

  users?: any;
  setUsers?: any
  setUsersTrigger?: any;
  usersTrigger?: any;
}

const context = createContext<UserContext>({});

const { Provider } = context;

interface Props {
  children: ReactChild | ReactChild[];
}

export const UserProvider: FC<Props> = ({ children }) => {
  const [user, setUser] = useState<any>();
  const [userTrigger, setUserTrigger] = useState<boolean>();
  
  const [users, setUsers] = useState<any>();
  const [usersTrigger, setUsersTrigger] = useState<boolean>();

  // useEffect(() => {
  //   const savedUser = getDataFromStorage('user');
  //   setUser({ ...savedUser, ...user, Phone_Number:Cookies.get("USER"), Role:Cookies.get("ROLE"), Token:Cookies.get("TOK") });
  // }, [userTrigger]);

  useEffect(() => {
    // (async () => {
    //   const theUsers = await fetchUsers();
    //   setUsers(theUsers.Data);
    // })();
  }, [usersTrigger]);

  return <Provider value={{ user, setUser, userTrigger, setUserTrigger , users, setUsers, usersTrigger, setUsersTrigger }}>{children}</Provider>;
};

export const useUser = () => useContext(context);
