
import LogoImg from 'assets/images/logo.png';
import { useUser } from 'contexts/user';
import { Navbar, Nav} from 'rsuite';
const Header = () => {
  const {user} = useUser();
  return (
    
<>
    <Navbar dir="ltr">
      <Navbar.Brand className="float-end pe-3" style={{padding:10}}>
        <img src={LogoImg} alt="" height="60"/>
      </Navbar.Brand>
    <Nav className={`mt-2 float-end`}></Nav>
  </Navbar>
</>
  );
};

export default Header;
