import { FC, useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";

import { useUser } from "contexts/user";
import { routes } from "router";

const RouteHandler: FC = () => {
  const { user, setUser } = useUser();
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    setLoading(false);
  }, [user]);

  const routing = useRoutes(routes(!!user?.token));

  if (loading)
    return (
      <div className="loading-table" />
    );

  return routing;
};

export default RouteHandler;
