import { FrontPage } from "pages/front-page";

import { AddBroker } from "pages/brokers/add-broker";
import Success from "pages/success/success";

interface Props {
  name: string;
}

const Dummy = ({ name }: Props) => <div style={{width:"100%", height:"30vh"}} className="d-flex align-items-center justify-content-center"><h1>{name}</h1></div>;

export const routes = (isLoggedIn: boolean) => [
  // {
  //   path: "/",
  //   element: <FrontPage />,
  // },
  {
    path: "/brokers/add/raw",
    element: <AddBroker parent="مواد اولیه" />,
  },
  {
    path: "/brokers/add/metal",
    element: <AddBroker parent="شمش، فلزات و آهن آلات" />,
  },
  {
    path: "/brokers/add/building",
    element: <AddBroker parent="مصالح ساختمانی" />,
  },
  {
    path: "/brokers/add/farm",
    element: <AddBroker parent="نهاده های کشاورزی و دامی" />,
  },
  {
    path: "/success/:code",
    element: <Success />,
  },

  {
    path: "*",
    element: <Dummy name="404" />,
  },
];
