import { appNetworkManager } from "./network-manager";
interface AddUserFormInputs {
  First_Name: string
  Last_Name: string
  Phone_Number: string
  Category : string
  Sub_Category : string
  Date : string
}

const Add_BROKER_URL =  "/brokers";
export const addBroker = (params: AddUserFormInputs) => {
  return appNetworkManager.post(Add_BROKER_URL, params);
};
