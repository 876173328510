import {
  FC,
  createContext,
  useState,
  ReactChild,
  useContext,
  useCallback,
  useEffect,
} from "react";
import { User } from "models/user";
import { Button, Modal } from 'rsuite';
interface ModalContext {
  test?:string;
  setTest?: any;
  modalFunc?: any;
  modalArgs?:any;
  modalBody?:any;
  modalTitle?:any;
  open?: any;
  handleOpen?:any;
  handleClose?:any;
  handleSuccessClose?: any;
  setModalFunc?:any;
  setModalArgs?:any;
  setModalBody?:any;
  setModalTitle?:any;
  setOpen?:any;
}

const context = createContext<ModalContext>({});

const { Provider } = context;

interface Props {
  children: ReactChild | ReactChild[];
}

export const ModalProvider: FC<Props> = ({ children }) => {
  const [test, setTest] = useState("5");



  type CallbackFunctionWithArgs = (...args:any[]) => void;

  const [modalFunc, setModalFunc] = useState<CallbackFunctionWithArgs>();
  const [modalArgs, setModalArgs] = useState();
  const [modalBody, setModalBody] = useState();
  const [modalTitle, setModalTitle] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback((func: any, arg: any, body="Are you sure", title="Are you sure?" ) => {
    setModalFunc(func);
    setModalArgs(arg);
    setModalBody(body);
    setModalTitle(title);
    setOpen(true);
  },[])
  const handleSuccessClose = useCallback(() => {
    setOpen(false);
    if (modalFunc) {
      console.log(modalFunc, modalArgs);
      modalFunc(modalArgs);
    }
  }, [modalFunc, modalArgs]);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Provider value={{test,
      setTest,
      modalFunc,
      modalArgs,
      modalBody,
      modalTitle,
      open,
      handleOpen,
      handleClose,
      handleSuccessClose,
      setModalFunc,
      setModalArgs,
      setModalBody,
      setModalTitle,
      setOpen}}>
      {children}
      <Modal open={open} onClose={handleClose} size="xs">
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBody}</Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSuccessClose} color="red" appearance="primary">Delete</Button>
          <Button onClick={handleClose} appearance="subtle">Cancel</Button>
        </Modal.Footer>
      </Modal>
    </Provider>
  );
};

export const useModal = () => useContext(context);
