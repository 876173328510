import { TickSquare } from "react-iconly";
import { useParams } from "react-router-dom";



const Success = () => {
  const { code } = useParams()
  return (
    <div className="content">
        <div className="row">
          <div className="col-12 text-center mt-5">
          <TickSquare set="bold" primaryColor={"#33ff55"} size={70} />
            <h4 className="mt-4">
              ثبت نام با موفقیت انجام شد
            </h4>
            <h4 className="mt-3">
              کد کارگزاری: {code}
            </h4>
          </div>
        </div>
      </div>
  );
};

export default Success;